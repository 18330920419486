export const Standard = {
  Classifications: "CLASSIFICATIONS",
  Concepts: "CONCEPTS",
  Definitions: "DEFINITIONS",
  Framework: "FRAMEWORK",
  Methodologies: "METHODOLOGIES",
  NomenclatureS: "NOMENCLATURES",
  Procedures: "PROCEDURES",
  Recommendations: "RECOMMENDATIONS",
  Protocols: "PROTOCOLS",
  Other: "OTHER",
};
