export const DataType = {
  Int: "INT",
  SmallInt: "SMALLINT",
  Long: "LONG",
  Double: "DOUBLE",
  Float: "FLOAT",
  Boolean: "BOOLEAN",
  Char: "CHAR",
  String: "STRING",
};
