export const Frequency = {
  Once: "ONCE",
  Daily: "DAILY",
  Weekly: "WEEKLY",
  Monthly: "MONTHLY",
  Quarterly: "QUARTERLY",
  Semiyearly: "SEMIYEARLY",
  Yearly: "YEARLY",
  Decennial: "DECENNIAL",
};
