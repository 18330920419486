/* Material design vue-material-design-icons*/

import ChevronRightBoxIcon from "vue-material-design-icons/ArrowRightBold.vue";
import AddIcon from "vue-material-design-icons/Plus.vue";
import ArrowDownIcon from "vue-material-design-icons/ArrowDownCircle.vue";
import ArrowRightIcon from "vue-material-design-icons/ArrowRightCircle.vue";
import MinusIcon from "vue-material-design-icons/Minus.vue";
import AddBoxIcon from "vue-material-design-icons/PlusBox.vue";
import CheckIcon from "vue-material-design-icons/CheckDecagram.vue";
import EditIcon from "vue-material-design-icons/SquareEditOutline.vue";
import DeleteIcon from "vue-material-design-icons/TrashCanOutline.vue";
import DocIcon from "vue-material-design-icons/FileDocumentOutline.vue";
import ViewIcon from "vue-material-design-icons/Magnify.vue";
import TextIcon from "vue-material-design-icons/TextBoxOutline.vue";
import UserIcon from "vue-material-design-icons/AccountOutline.vue";
import UsersIcon from "vue-material-design-icons/AccountMultipleOutline.vue";
import RegulationIcon from "vue-material-design-icons/Bank.vue";
import GsimIcon from "vue-material-design-icons/Puzzle.vue";
import GsimOutlineIcon from "vue-material-design-icons/PuzzleOutline.vue";
import StandardIcon from "vue-material-design-icons/TextBoxCheckOutline.vue";

export const materialIconsSet = Object.assign(
  {},
  {
    ChevronRightBoxIcon,
    ArrowDownIcon,
    ArrowRightIcon,
    AddIcon,
    MinusIcon,
    AddBoxIcon,
    CheckIcon,
    EditIcon,
    DeleteIcon,
    DocIcon,
    ViewIcon,
    TextIcon,
    UserIcon,
    UsersIcon,
    RegulationIcon,
    GsimIcon,
    GsimOutlineIcon,
    StandardIcon,
  },
  {}
);
